.register-btn {
    -st-extends: StylableButton;
    transition: all 0.2s ease, visibility 0s;
    padding: 6px 22px;
    margin-top: 20px;
    border-radius: 50px;
    border: 0px solid rgb(63, 118, 82);
    background: #D4AE4C;
    font-family: "arial black", arial-w01-black, arial-w02-black, "arial-w10 black", sans-serif;

}

.sb-description h5 {
    font-weight: 400;
    margin: 0;
    font-size: 18px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: normal;
}

.sb-description .desc {
    margin-top: 10px;
    white-space: pre-wrap;
    font-family: inherit;
    font-size: 12px;
    line-height: 17px;
}

.starsss {
    fill: rgb(212, 174, 76);
    ;
}

hr {

    border-top: 10px dashed rgb(212, 174, 76);
    color: #fff;
    /* background-color: #fff; */
    height: 1px;
    width: 76%;

}


.swiper {
    width: 471px;
    height: 570px;
    /* position: absolute; */
    /* left: 50%; */
    /* top: 50%; */
    /* margin-left: -150px; */
    /* margin-top: -150px; */
}

.swiper-slide {
    background-position: center;
    background-size: cover;
}

.swiper-slide img {
    display: block;
    width: 100%;
}

.nav-link.footer-link {
    display: flex;
    padding: 10px 0;
    justify-content: center;
    color: #C48C1B;
}